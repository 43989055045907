<template>
  <v-container>
    <portal to="appBarContentLeft">
      <span class="headline font-weight-light">My Account</span>
    </portal>

    <v-card flat outlined class="mb-4" v-if="user">
      <v-toolbar flat>
        <v-toolbar-title>Account Information</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <v-layout row wrap class="ma-0">
          <v-flex xs12 md3 lg3 xl3>
            <v-text-field
              dense
              class="ma-1"
              outlined
              v-model="user.id"
              label="Account ID"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 lg3 xl3>
            <v-text-field
              dense
              v-if="org"
              class="ma-1"
              outlined
              v-model="org.id"
              label="Organization ID"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 lg3 xl2>
            <v-text-field
              dense
              class="ma-1"
              outlined
              v-model="user.email"
              label="Account Email"
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="ma-0">
          <v-flex xs12 md3 lg2 xl1>
            <v-text-field
              dense
              class="ma-1"
              ref="firstNameField"
              outlined
              v-model="firstName"
              label="First Name"
              @blur="saveUpdates()"
              @change="changeDetected = true"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 lg3 xl2>
            <v-text-field
              dense
              class="ma-1"
              ref="lastNameField"
              outlined
              v-model="lastName"
              label="Last Name"
              @blur="saveUpdates()"
              @change="changeDetected = true"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>

    <v-card v-if="org" outlined flat>
      <v-toolbar flat>
        <v-toolbar-title>Email Alert Settings</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-simple-table style="width: 100%;">
        <template v-slot:default>
          <tbody>
            <tr :class="alternatingColors(0)">
              <td>
                <v-list-item two-line class="ml-0 pl-0">
                  <v-list-item-content class="ml-0 pl-0">
                    <v-list-item-title>Threshold Breaches</v-list-item-title>
                    <v-list-item-subtitle
                      >You'll be sent an email when a threshold breach occurs
                      for any patient in the organization.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch
                      @change="updateNotifyOnPatientAlert()"
                      v-model="notifyOnPatientAlert"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import bus from '@/core/helpers/bus'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      password: 'password',
      changeDetected: false,
      notifyOnPatientAlert: false,
      firstName: null,
      lastName: null,
    }
  },
  components: {},
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('org', ['org']),
  },
  watch: {},
  methods: {
    ...mapActions('auth', ['updateUser']),
    async updateNotifyOnPatientAlert() {
      await this.updateUser({ settings: { notifications: { onPatientAlert: this.notifyOnPatientAlert } } })
      bus.$emit('toast', { type: 'success', text: 'Account Updated' })
    },
    async saveUpdates() {
      if (this.changeDetected) {
        await this.updateUser({ firstName: this.firstName, lastName: this.lastName })
        this.changeDetected = false
        bus.$emit('toast', { type: 'success', text: 'Account Updated' })
      }
    },
  },
  mounted() {
    this.firstName = this.user.firstName
    this.lastName = this.user.lastName
    if (this.user.settings?.notifications?.onPatientAlert) {
      this.notifyOnPatientAlert = true
    }
  },
}
</script>
